import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login';
import ConVerify from './components/ConVerify';
import ConServices from './components/ConServices';
import ContractorSignUpStart from './components/ContractorSignUpStart';
import ConBio from './components/ConBio';
import ConShowcase from './components/ConShowcase';
import ConStripe from './components/ConStripe';
import ConComplete from './components/ConComplete';
import Logout from './components/Logout';
import ProtectedRoute from './components/ProtectedRoute';
import SignUp from './components/SignUp';
import './App.css';
import { initializeApp } from 'firebase/app';
import { VendorProvider } from './contexts/VendorContext';

const firebaseConfig = {
  apiKey: "AIzaSyCo272EimaB-Whpf5s6PWdVSNhHaVJLlws",
  authDomain: "home-runns.firebaseapp.com",
  databaseURL: "https://home-runns-default-rtdb.firebaseio.com",
  projectId: "home-runns",
  storageBucket: "home-runns.appspot.com",
  messagingSenderId: "530822910309",
  appId: "1:530822910309:web:c6b0ae9c6ce9be5ed4518c",
  measurementId: "G-MWV0Q2Z2D4"
};

const app = initializeApp(firebaseConfig);

function App() {
  return (
    <VendorProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<Navigate to="/signup" replace />} />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/contractor-signup" element={<ContractorSignUpStart />} />
            <Route path="/converify" element={
              <ProtectedRoute>
                <ConVerify />
              </ProtectedRoute>
            } />
            <Route path="/conservices" element={
              <ProtectedRoute>
                <ConServices />
              </ProtectedRoute>
            } />
            <Route path="/conbio" element={
              <ProtectedRoute>
                <ConBio />
              </ProtectedRoute>
            } />
            <Route path="/conshowcase" element={
              <ProtectedRoute>
                <ConShowcase />
              </ProtectedRoute>
            } />
            <Route path="/constripe" element={
              <ProtectedRoute>
                <ConStripe />
              </ProtectedRoute>
            } />
            <Route path="/concomplete" element={
              <ProtectedRoute>
                <ConComplete />
              </ProtectedRoute>
            } />
            {/* Add more routes as needed */}
          </Routes>
        </div>
      </Router>
    </VendorProvider>
  );
}

export default App;
