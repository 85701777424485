import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import appStoreBadge from '../assets/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';
import playStoreBadge from '../assets/GetItOnGooglePlay_Badge_Web_color_English.png';
import './ConComplete.css';
import { api } from '../api';

function ConComplete() {
  const navigate = useNavigate();
  const [vendorInfo, setVendorInfo] = useState(null);
  const [stripeRequirements, setStripeRequirements] = useState(null);
  const [stripeAccountLink, setStripeAccountLink] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showStripeRequirements, setShowStripeRequirements] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchData(user.uid);
      } else {
        console.log('User is not logged in');
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const fetchData = async (uid) => {
    try {
      const vendorData = await api.getVendorInfo(uid);
      setVendorInfo(vendorData);
      console.log(vendorData);
      if (!vendorData.stripeVerified) {
                // Fetch Stripe account link
        const accountLinkResponse = await api.getStripeAccountLink(uid);
        setStripeAccountLink(accountLinkResponse.url);  // Use the url field from the response
        const stripeData = await api.getStripeAccount(uid);
        const allRequirements = [
          ...new Set([
            ...stripeData.requirements.currently_due,
            ...stripeData.requirements.eventually_due
          ])
        ].map(translateStripeRequirement);
        // Deduplicate the requirements
        const uniqueRequirements = [...new Set(allRequirements)];
        setStripeRequirements(uniqueRequirements);


      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };
  const handleBackToStripe = () => {
    navigate('/constripe');
  };

  const handleLogout = async () => {
    try {
      const auth = getAuth();
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const translateStripeRequirement = (requirement) => {
    const translations = {
      'individual.first_name': 'Your First Name',
      'individual.last_name': 'Your Last Name',
      'individual.dob.day': 'Your Date of Birth',
      'individual.dob.month': 'Your Date of Birth',
      'individual.dob.year': 'Your Date of Birth',
      'individual.phone': 'Your Phone Number',
      'individual.email': 'Your Email Address',
      'individual.address.line1': 'Your Address',
      'individual.address.city': 'Your City',
      'individual.address.state': 'Your State',
      'individual.address.postal_code': 'Your Postal Code',
      'individual.ssn_last_4': 'Last 4 digits of your SSN',
      'business.name': 'Your Business Name',
      'business.tax_id': 'Your Tax ID',
      'business.address.line1': 'Your Business Address',
      'business.address.city': 'Your Business City',
      'business.address.state': 'Your Business State',
      'business.address.postal_code': 'Your Business Postal Code',
      'business.phone': 'Your Business Phone Number',
      'business_profile.product_description': 'Your Product Description',
      'business_profile.support_phone': 'Your Support Phone Number',
      'business_profile.url': 'Your Business URL',
      'external_account': 'An External Bank Account',
      'tos_acceptance.date': 'Acceptance of Terms of Service',
      'tos_acceptance.ip': 'Acceptance of Terms of Service',
    };
    return translations[requirement] || `${requirement}`;
  };

  const toggleStripeRequirements = () => {
    setShowStripeRequirements(!showStripeRequirements);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleContinueToStripe = () => {
    if (stripeAccountLink) {
      window.open(stripeAccountLink, '_blank', 'noopener,noreferrer');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="concomplete-container">
      <div className="concomplete-box">
        <img src="https://homerunns.com/wp-content/uploads/2024/09/Logo_White.png" alt="HomeRunns Logo" className="concomplete-logo" />
        
        {vendorInfo && vendorInfo.verified ? (
          <>
            <h1>Verification Completed!</h1>
            <div className="header-underline"></div>
            <div className="success-message">
              <p className="verification-text">
                {vendorInfo.conInfo && vendorInfo.conInfo.companyName ? (
                  <><strong>{vendorInfo.conInfo.companyName}</strong> is now fully verified with <strong>HomeRunns</strong> and can receive requests for service.</>
                ) : (
                  <>Your company is now fully verified with <strong>HomeRunns</strong> and can receive requests for service.</>
                )}
              </p>
            </div>
          </>
        ) : (
          <>
            <h1>More Information Required!</h1>
            <div className="header-underline"></div>
            <p className="subheader-text">Complete the steps below to become a fully verified <strong>HomeRunns</strong> contractor.</p>
            
            {vendorInfo && (
              <div className="profile-requirements">
                <ul>
                  {!vendorInfo.conInfo?.address1 && (
                    <li>
                      <Link to="/conbio" className="requirement-button">Add an Address to your profile</Link>
                    </li>
                  )}
                  {!vendorInfo.conInfo?.selectedServices && (
                    <li>
                      <Link to="/conservices" className="requirement-button">Select provided services in your profile</Link>
                    </li>
                  )}
                  {vendorInfo.conInfo?.travelDistance === undefined && (
                    <li>
                      <Link to="/conbio" className="requirement-button">Set your travel distance in your profile</Link>
                    </li>
                  )}
                  {!vendorInfo?.stripeVerified && stripeAccountLink && (
                    <li className="stripe-requirement">
                      <a href={stripeAccountLink} target="_blank" rel="noopener noreferrer" className="requirement-button">Complete Stripe Profile</a>
                      <button onClick={toggleModal} className="toggle-requirements">
                        What will Stripe ask for?
                      </button>
                    </li>
                  )}
                </ul>
              </div>
            )}
          </>
        )}
        
        <div className="download-section">
          <p className="download-text">Click below to download the app to your device to get started with <strong>HomeRunns</strong>.</p>
          <div className="app-store-links">
            <a href="https://apps.apple.com/us/app/homerunns/id6479625001" target="_blank" rel="noopener noreferrer">
              <img src={appStoreBadge} alt="Download on the App Store" className="app-store-badge" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.app.homerunns&pcampaignid=web_share" target="_blank" rel="noopener noreferrer">
              <img src={playStoreBadge} alt="Get it on Google Play" className="play-store-badge" />
            </a>
          </div>
        </div>
        
        <div className="button-container">
          <button onClick={handleBackToStripe} className="back-to-stripe-button">Back to Stripe</button>
          <button onClick={handleLogout} style={{ backgroundColor: 'red', transition: 'background-color 0.3s' }} className="back-to-stripe-button" onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#ff4d4d'} onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'red'}>Logout</button>
        </div>
      </div>
      {showModal && (
        <div className="modal-overlay" onClick={toggleModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button onClick={toggleModal} className="close-modal-x">&times;</button>
            <h2 className="modal-title">Required to complete verification:</h2>
            <ul className="stripe-requirements-list">
              {stripeRequirements && stripeRequirements.map((req, index) => (
                <li key={`stripe-${index}`} className="stripe-requirement-item">{req}</li>
              ))}
            </ul>
            {stripeAccountLink && (
              <button onClick={handleContinueToStripe} className="continue-to-stripe-button">Continue to Stripe</button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default ConComplete;
