let googleMapsPromise = null;

const loadGoogleMapsAPI = () => {
  if (!googleMapsPromise) {
    googleMapsPromise = new Promise((resolve) => {
      window.initGoogleMapsAPI = () => {
        resolve(window.google);
      };

      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyB5O8fzZ_rA2YSWBAsg6w0wC1QWPGXxzrA&libraries=places&callback=initGoogleMapsAPI`;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
    });
  }
  return googleMapsPromise;
};

export default loadGoogleMapsAPI;
