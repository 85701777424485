import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './SignUp.css'; // We'll create this file next

function SignUp() {
  const navigate = useNavigate();

  return (
    <div className="signup-container">
      <div className="signup-box">
        <img src="https://homerunns.com/wp-content/uploads/2024/09/Logo_White.png" alt="HomeRunns Logo" className="signup-logo" />
        <h1>Are you a Homeowner or Contractor?</h1>
        <div className="button-container">
          <button className="signup-button homeowner" disabled>Homeowner</button>
          <button className="signup-button contractor" onClick={() => navigate('/contractor-signup')}>Contractor</button>
        </div>
        <p className="login-link">
          Already have an account? <Link to="/login">Log In</Link>
        </p>
      </div>
    </div>
  );
}

export default SignUp;
