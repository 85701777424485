import { urlnv } from './utils/environmentUtils';

const API_BASE_URL = `https://${urlnv()}api-7jrujiqd5q-uc.a.run.app`;
const API_STRIPE_URL = `https://${urlnv()}stripe-7jrujiqd5q-uc.a.run.app`;
const API_KEY = 'jY0uVq5tP1a8sD3f6gH2b9N4cW0R';
const headers = {
  'Content-Type': 'application/json',
  'X-API-KEY': API_KEY,
};

const handleResponse = async (response) => {
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'An error occurred');
  }
  return response.json();
};


export const createContractor = async (uid, contractorData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/consignup/${uid}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(contractorData),
    });
    return handleResponse(response);
  } catch (error) {
    throw error;
  }
};

export const getContractorServices = async (uid) => {
  try {
    const response = await fetch(`${API_BASE_URL}/coninfo/${uid}`, {
      method: 'GET',
      headers,
    });
    return handleResponse(response);
  } catch (error) {
    throw error;
  }
};

export const updateContractorServices = async (uid, selectedServices) => {
  try {
    const response = await fetch(`${API_BASE_URL}/coninfo/${uid}`, {
      method: 'PATCH',
      headers,
      body: JSON.stringify({ selectedServices }),
    });
    return handleResponse(response);
  } catch (error) {
    throw error;
  }
};

export const updateContractorInfo = async (uid, contractorInfo) => {
  try {
    const response = await fetch(`${API_BASE_URL}/coninfo/${uid}`, {
      method: 'PATCH',
      headers,
      body: JSON.stringify(contractorInfo),
    });
    return handleResponse(response);
  } catch (error) {
    throw error;
  }
};

export const updateGeocoordinate = async (uid, geocoordinateData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/geocoordinates/${uid}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(geocoordinateData),
    });
    return handleResponse(response);
  } catch (error) {
    throw error;
  }
};

export const getStripeAccountLink = async (uid) => {
  try {
    const response = await fetch(`${API_STRIPE_URL}/account_links`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ uid }),
    });
    return handleResponse(response);
  } catch (error) {
    throw error;
  }
};

export const getStripeAccount = async (uid) => {
  try {
    const response = await fetch(`${API_BASE_URL}/stripeaccts/${uid}`, {
      method: 'GET',
      headers,
    });
    return handleResponse(response);
  } catch (error) {
    throw error;
  }
};

export const getVendorInfo = async (uid) => {
  try {
    const response = await fetch(`${API_BASE_URL}/vendors/${uid}`, {
      method: 'GET',
      headers,
    });

    if (!response.ok) {
      throw new Error('Failed to fetch vendor info');
    }

    const data = await response.json();


    return data;
  } catch (error) {
    console.error('Error in getVendorInfo:', error);
    throw error;
  }
};

// Add more API functions here as needed
// For example:
// export const loginUser = async (credentials) => { ... }
// export const getContractorProfile = async (contractorId) => { ... }

export const api = {
  get: async (endpoint) => {
    const response = await fetch(`${API_BASE_URL}${endpoint}`, { headers });
    return handleResponse(response);
  },
  post: async (endpoint, data) => {
    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(data),
    });
    return handleResponse(response);
  },
  patch: async (endpoint, data) => {
    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
      method: 'PATCH',
      headers,
      body: JSON.stringify(data),
    });
    return handleResponse(response);
  },
  getContractorServices,
  updateContractorServices,
  updateContractorInfo,
  updateGeocoordinate,
  getStripeAccountLink,
  getStripeAccount,
  getVendorInfo,
  // Add other methods (PUT, DELETE, etc.) as needed
};

export default api;
