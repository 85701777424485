import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaUser, FaCamera } from 'react-icons/fa';
import { getAuth } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import api from '../api';
import loadGoogleMapsAPI from '../utils/googleMapsLoader';
import './ConBio.css';

function ConBio() {
  const [companyName, setCompanyName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [legalAddress, setLegalAddress] = useState('');
  const [maxTravelDistance, setMaxTravelDistance] = useState('25');
  const [experience, setExperience] = useState('');
  const [photoURL, setPhotoURL] = useState(null);
  const [companyIntroduction, setCompanyIntroduction] = useState('');
  const [loading, setLoading] = useState(true);
  const [uploadingPhoto, setUploadingPhoto] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const auth = getAuth();
  const storage = getStorage();
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const autocompleteService = useRef(null);
  const [addressComponents, setAddressComponents] = useState({
    street: '',
    city: '',
    state: '',
    zip: ''
  });
  const placesServiceRef = useRef(null);
  const [geocoordinate, setGeocoordinate] = useState(null);

  useEffect(() => {
    const fetchContractorInfo = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          throw new Error('No authenticated user found');
        }

        const response = await api.getContractorServices(user.uid);
        if (response) {
          setCompanyName(response.companyName || '');
          setPhoneNumber(response.phoneNumber || '');
          setLegalAddress(response.address1 || '');
          setMaxTravelDistance(response.travelDistance || '25');
          setExperience(response.yearsOfService || '');
          setCompanyIntroduction(response.bio || '');
          
          // Check if a photo exists in Firebase Storage
          const storageRef = ref(storage, `userData/${user.uid}/profilePicture.jpg`);
          try {
            const url = await getDownloadURL(storageRef);
            setPhotoURL(url);
          } catch (error) {
            // If no photo exists, this will throw an error, which we can ignore
            console.log('No existing profile picture found');
          }
        }
      } catch (err) {
        console.error('Error fetching contractor info:', err);
        setError('Failed to load your information. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchContractorInfo();

    // Load Google Maps API
    loadGoogleMapsAPI().then((google) => {
      autocompleteService.current = new google.maps.places.AutocompleteService();
      placesServiceRef.current = new google.maps.places.PlacesService(document.createElement('div'));
    });

    return () => {
      // Remove the global callback when component unmounts
      delete window.initAutocomplete;
    };
  }, [auth, storage]);

  const handleAddressChange = (e) => {
    const value = e.target.value;
    setLegalAddress(value);

    // Extract zip code from manually entered address
    const zipMatch = value.match(/\b\d{5}(?:-\d{4})?\b/);
    if (zipMatch) {
      setAddressComponents(prev => ({ ...prev, zip: zipMatch[0] }));
    }

    if (value.length > 2 && autocompleteService.current) {
      autocompleteService.current.getPlacePredictions(
        { 
          input: value, 
          types: ['address'],
          componentRestrictions: { country: 'us' } // Restrict to US addresses
        },
        (predictions, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            setAddressSuggestions(predictions);
          } else {
            setAddressSuggestions([]);
          }
        }
      );
    } else {
      setAddressSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    placesServiceRef.current.getDetails(
      { placeId: suggestion.place_id, fields: ['address_components', 'geometry', 'formatted_address'] },
      (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const addressComp = {
            street: '',
            city: '',
            state: '',
            zip: '',
            country: ''
          };

          place.address_components.forEach(component => {
            const type = component.types[0];
            if (type === 'street_number' || type === 'route') {
              addressComp.street += component.long_name + ' ';
            } else if (type === 'locality') {
              addressComp.city = component.long_name;
            } else if (type === 'administrative_area_level_1') {
              addressComp.state = component.short_name;
            } else if (type === 'postal_code') {
              addressComp.zip = component.long_name;
            } else if (type === 'country') {
              addressComp.country = component.long_name;
            }
          });

          setAddressComponents(addressComp);
          setLegalAddress(place.formatted_address);
          
          // Set geocoordinate
          if (place.geometry && place.geometry.location) {
            setGeocoordinate({
              latitude: place.geometry.location.lat(),
              longitude: place.geometry.location.lng()
            });
          }
        }
      }
    );
    setAddressSuggestions([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('No authenticated user found');
      }

      const contractorInfo = {
        companyName,
        phoneNumber,
        address1: legalAddress,
        travelDistance: maxTravelDistance,
        yearsOfService: experience,
        bio: companyIntroduction,
      };

      await api.updateContractorInfo(user.uid, contractorInfo);

      // Update geocoordinate if available
      if (geocoordinate && legalAddress) {
        await api.updateGeocoordinate(user.uid, {
          geocoordinate: {
            ...geocoordinate,
            privacy: "public",
            travelDistance: maxTravelDistance
          }
        });
      }

      navigate('/conshowcase');
    } catch (err) {
      console.error('Error updating contractor info:', err);
      setError('Failed to update your information. Please try again.');
    } finally {
      setLoading(false);
    }
  };


    const handleBackToServices  = async (e) => {
      e.preventDefault();
      setLoading(true);
      setError(null);
  
      try {
        const user = auth.currentUser;
        if (!user) {
          throw new Error('No authenticated user found');
        }
  
        const contractorInfo = {
          companyName,
          phoneNumber,
          address1: legalAddress,
          travelDistance: maxTravelDistance,
          yearsOfService: experience,
          bio: companyIntroduction,
        };
  
        await api.updateContractorInfo(user.uid, contractorInfo);
  
        // Update geocoordinate if available
        if (geocoordinate && legalAddress) {
          await api.updateGeocoordinate(user.uid, {
            geocoordinate: {
              ...geocoordinate,
              privacy: "public",
              travelDistance: maxTravelDistance
            }
          });
        }
  
        navigate('/conservices');
      } catch (err) {
        console.error('Error updating contractor info:', err);
        setError('Failed to update your information. Please try again.');
      } finally {
        setLoading(false);
      }
    };

  const handleSkip = () => {
    navigate('/concomplete');
  };
  const handlePhotoUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploadingPhoto(true);
      try {
        const user = auth.currentUser;
        if (!user) {
          throw new Error('No authenticated user found');
        }

        const storageRef = ref(storage, `userData/${user.uid}/profilePicture.jpg`);
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);
        setPhotoURL(downloadURL);
      } catch (error) {
        console.error('Error uploading photo:', error);
        setError('Failed to upload photo. Please try again.');
      } finally {
        setUploadingPhoto(false);
      }
    }
  };

  const handlePhotoClick = () => {
    fileInputRef.current.click();
  };

  const handleRemovePhoto = async (e) => {
    e.stopPropagation();
    setUploadingPhoto(true);
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('No authenticated user found');
      }

      // Remove the photo from Firebase Storage
      const storageRef = ref(storage, `userData/${user.uid}/profilePicture.jpg`);
      await deleteObject(storageRef);

      // Update the state
      setPhotoURL(null);
    } catch (error) {
      console.error('Error removing photo:', error);
      setError('Failed to remove photo. Please try again.');
    } finally {
      setUploadingPhoto(false);
    }
  };

  if (loading) {
    return (
      <div className="conbio-container">
        <div className="conbio-box">
          <div className="loading-container">
            <div className="loading-spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="conbio-container">
      <div className="conbio-box">
        <img src="https://homerunns.com/wp-content/uploads/2024/09/Logo_White.png" alt="HomeRunns Logo" className="conbio-logo" />
        <h1>Share more details about your business</h1>
        <form onSubmit={handleSubmit}>
          <div className="form-columns">
            <div className="form-column">
              <div className="form-field">
                <label htmlFor="companyName" className="field-label">Company Legal Name</label>
                <input
                  id="companyName"
                  type="text"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  placeholder="Enter company legal name"
                />
              </div>
              <div className="form-field">
                <label htmlFor="phoneNumber" className="field-label">Phone Number</label>
                <input
                  id="phoneNumber"
                  type="tel"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="Enter phone number"
                />
              </div>
              <div className="form-field">
                <label htmlFor="legalAddress" className="field-label">Legal Address</label>
                <input
                  id="legalAddress"
                  type="text"
                  value={legalAddress}
                  onChange={handleAddressChange}
                  placeholder="Enter legal address"
                />
                {addressSuggestions.length > 0 && (
                  <ul className="address-suggestions">
                    {addressSuggestions.map((suggestion) => (
                      <li
                        key={suggestion.place_id}
                        onClick={() => handleSuggestionClick(suggestion)}
                      >
                        {suggestion.description}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="form-field">
                <label htmlFor="maxTravelDistance" className="field-label">Max Travel Distance (miles)</label>
                <input
                  id="maxTravelDistance"
                  type="number"
                  value={maxTravelDistance}
                  onChange={(e) => setMaxTravelDistance(e.target.value)}
                  placeholder="Enter max travel distance"
                />
              </div>
              <div className="form-field">
                <label htmlFor="experience" className="field-label">Experience (years)</label>
                <input
                  id="experience"
                  type="number"
                  value={experience}
                  onChange={(e) => setExperience(e.target.value)}
                  placeholder="Enter years of experience"
                />
              </div>
            </div>
            <div className="form-column">
              <div className="photo-upload-container" onClick={handlePhotoClick}>
                <h3>Profile Photo</h3>
                <div className="photo-placeholder">
                  {photoURL ? (
                    <>
                      <img src={photoURL} alt="Profile" className="photo-preview" />
                      <div className="photo-remove-button" onClick={handleRemovePhoto}></div>
                    </>
                  ) : (
                    <>
                      <FaUser className="user-icon" />
                      <FaCamera className="camera-icon" />
                    </>
                  )}
                </div>
                <input
                  ref={fileInputRef}
                  type="file"
                  accept="image/*"
                  onChange={handlePhotoUpload}
                  className="photo-upload-input"
                  style={{ display: 'none' }}
                />
                {uploadingPhoto && <p>Uploading...</p>}
              </div>
            </div>
          </div>
          <h1>Introduce your business</h1>

          <div className="form-field">
            <textarea
              id="companyIntroduction"
              value={companyIntroduction}
              onChange={(e) => setCompanyIntroduction(e.target.value)}
              placeholder="Tell us about your company..."
              className="company-introduction"
              rows="4"
            />
          </div>
          <div className="button-container">
            <button type="button" onClick={handleBackToServices} className="back-to-services-button">Back to Services</button>
            <div className="spacer"></div>
            <button type="submit" className="submit-button" disabled={loading}>
              {loading ? 'Updating...' : 'Continue'}
            </button>
          </div>
        </form>
        {error && <p className="error-message">{error}</p>}
        <a href="#" onClick={handleSkip} className="skip-link">Skip for now</a>
      </div>
    </div>
  );
}

export default ConBio;
