import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import api from '../api';
import './ConServices.css';
import './ConBio.css'; // Import the CSS file with the loading animation

// Static list of services, sorted alphabetically
const servicesList = [
  "Carpentry", "Deep Cleaning", "Demolition", "Design", "Electrical",
  "Fencing", "Fireplace", "Flooring", "Garage Doors", "Handyman",
  "Home Technology", "HVAC", "Landscaping", "Painting", "Pest Control",
  "Plumbing", "Pool Maintenance", "Pressure Washing", "Remodeling",
  "Roofing", "Windows"
].sort();

function ConServices() {
  const [selectedServices, setSelectedServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          throw new Error('No authenticated user found');
        }

        const response = await api.getContractorServices(user.uid);
        if (response.selectedServices) {
          setSelectedServices(response.selectedServices);
        }
      } catch (err) {
        console.warn('Fetching services may not have succeeded:', err);
        // setError('It seems there was an issue loading your services, but you can still proceed.');
      } finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, []);

  const handleServiceToggle = (service) => {
    setSelectedServices(prevSelected =>
      prevSelected.includes(service)
        ? prevSelected.filter(s => s !== service)
        : [...prevSelected, service]
    );
  };

  const handleSubmit = async () => {
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('No authenticated user found');
      }

      await api.updateContractorServices(user.uid, selectedServices);
      navigate('/conbio');
    } catch (err) {
      console.error('Error updating services:', err);
      setError('Failed to update your services. Please try again.');
    }
  };

  const handleSkip = () => {
    navigate('/concomplete');
  };

  // Split services into three columns
  const columnLength = Math.ceil(servicesList.length / 3);
  const columns = [
    servicesList.slice(0, columnLength),
    servicesList.slice(columnLength, columnLength * 2),
    servicesList.slice(columnLength * 2)
  ];

  if (loading) {
    return (
      <div className="conservices-container">
        <div className="conservices-box">
          <div className="loading-container">
            <div className="loading-spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="conservices-container">
      <div className="conservices-box">
        <img src="https://homerunns.com/wp-content/uploads/2024/09/Logo_White.png" alt="HomeRunns Logo" className="conservices-logo" />
        <h1>Select the services you provide</h1>
        <div className="header-underline"></div>
        {error && <p className="error-message">{error}</p>}
        <div className="services-list">
          {columns.map((column, index) => (
            <div key={index} className="services-column">
              {column.map((service) => (
                <button
                  key={service}
                  className={`service-button ${selectedServices.includes(service) ? 'selected' : ''}`}
                  onClick={() => handleServiceToggle(service)}
                >
                  {service}
                </button>
              ))}
            </div>
          ))}
        </div>
        <div className="button-container">
          <div className="spacer"></div>
          <button onClick={handleSubmit} className="submit-button">Continue</button>
        </div>
        <a href="#" onClick={handleSkip} className="skip-link">Skip for now</a>
      </div>
    </div>
  );
}

export default ConServices;
