export const environment = "production"; // You can change this manually as needed

export function nv(str) {
  let prefix = '';
  if (environment === 'development') {
    prefix = 'dev_';
  } else if (environment === 'staging') {
    prefix = 'stg_';
  } else if (environment === 'production') {
    prefix = 'prd_';
  }
  return prefix + str;
}

export function urlnv() {
  let prefix = '';
  if (environment === 'development') {
    prefix = 'dev-';
  } else if (environment === 'staging2') {
    prefix = 'stg2-';
  } else if (environment === 'staging') {
    prefix = 'stg-';
  } else if (environment === 'production') {
    prefix = 'prd-';
  } else if (environment === null) {
    prefix = 'null-';
  }
  return prefix;
}
