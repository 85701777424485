import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { ReactComponent as StripeWordmark } from '../assets/Stripe wordmark - white.svg';
import './ConStripe.css';
import { getStripeAccountLink } from '../api';

function ConStripe() {
  const navigate = useNavigate();
  const [stripeUrl, setStripeUrl] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchStripeAccountLink = async () => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        try {
          const response = await getStripeAccountLink(user.uid);
          setStripeUrl(response.url);
          setIsLoading(false);
        } catch (err) {
          console.error('Error fetching Stripe account link:', err);
          setError('Failed to load Stripe onboarding link. Please try again later.');
          setIsLoading(false);
        }
      } else {
        setError('User not authenticated. Please log in and try again.');
        setIsLoading(false);
      }
    };

    fetchStripeAccountLink();
  }, []);

  const handleBackToShowcase = () => {
    navigate('/conshowcase');
  };

  const handleContinue = () => {
    navigate('/concomplete');
  };

  const handleSkip = () => {
    console.log('Skipped ConStripe');
    navigate('/concomplete');
  };

  const handleStripeOnboarding = () => {
    if (stripeUrl) {
      window.open(stripeUrl, '_blank');
    } else {
      setError('Stripe onboarding link is not available. Please try again later.');
    }
  };

  return (
    <div className="constripe-container">
      <div className="constripe-box">
        <img src="https://homerunns.com/wp-content/uploads/2024/09/Logo_White.png" alt="HomeRunns Logo" className="constripe-logo" />
        <h1>Receive secure payments</h1>
        <div className="header-underline"></div>
        <div className="stripe-section">
          <StripeWordmark className="stripe-logo" />
          <p>Stripe is a payment service provider that millions of businesses of all sizes --from startups to large enterprises-- use to accept payments, send payouts, and manage their businesses online.</p>
        </div>
        <div className="homerunns-section">
          <img src="https://homerunns.com/wp-content/uploads/2024/09/Logo_White.png" alt="HomeRunns Logo" className="homerunns-logo" />
          <p>HomeRunns collects the payment from the customer and pays you directly, both via Stripe.</p>
        </div>
        <div className="header-underline"></div>
        <button 
          onClick={handleStripeOnboarding} 
          className={`stripe-onboarding-button ${isLoading ? 'loading' : ''}`}
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <span className="spinner"></span>
              Preparing Stripe
            </>
          ) : 'Open Stripe Onboarding'}
        </button>
        {error && <p className="error-message">{error}</p>}
        <div className="button-container">
          <button onClick={handleBackToShowcase} className="back-to-showcase-button">Back to Showcase</button>
          <div className="spacer"></div>
          <button onClick={handleContinue} className="submit-button">Continue</button>
        </div>
        <a href="#" onClick={handleSkip} className="skip-link">Skip for now</a>
      </div>
    </div>
  );
}

export default ConStripe;
