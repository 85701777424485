import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaPlus, FaTimes } from 'react-icons/fa';
import { getAuth } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL, listAll, deleteObject } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import api from '../api';
import './ConShowcase.css';

function ConShowcase() {
  const [photos, setPhotos] = useState([]);
  const [workersCompensation, setWorkersCompensation] = useState('');
  const [insurance, setInsurance] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const auth = getAuth();
  const storage = getStorage();

  useEffect(() => {
    const fetchShowcaseInfo = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          throw new Error('No authenticated user found');
        }

        // Fetch contractor info
        const conInfo = await api.getContractorServices(user.uid);
        setWorkersCompensation(conInfo.workersComp || '');
        setInsurance(conInfo.insurance || '');

        // Fetch photos directly from Firebase Storage
        const photosRef = ref(storage, `userData/${user.uid}/displayPhotos`);
        const photosList = await listAll(photosRef);
        const photoURLs = await Promise.all(
          photosList.items.slice(0, 5).map(item => getDownloadURL(item))
        );
        setPhotos(photoURLs);

      } catch (err) {
        console.error('Error fetching showcase info:', err);
        setError('Failed to load your information. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchShowcaseInfo();
  }, [auth, storage]);

  const handlePhotoUpload = async (e) => {
    const files = Array.from(e.target.files);
    const user = auth.currentUser;
    if (!user) return;

    const newPhotos = [];
    for (let file of files) {
      if (photos.length + newPhotos.length >= 5) break;
      const photoId = uuidv4();
      const photoRef = ref(storage, `userData/${user.uid}/displayPhotos/${photoId}.jpg`);
      await uploadBytes(photoRef, file);
      const photoURL = await getDownloadURL(photoRef);
      newPhotos.push(photoURL);
    }

    setPhotos(prevPhotos => [...prevPhotos, ...newPhotos].slice(0, 5));
  };

  const handlePhotoRemove = async (photoURL) => {
    const user = auth.currentUser;
    if (!user) return;

    try {
      // Remove the photo from Firebase Storage
      const photoRef = ref(storage, photoURL);
      await deleteObject(photoRef);

      // Update the photos state
      setPhotos(prevPhotos => prevPhotos.filter(url => url !== photoURL));
    } catch (error) {
      console.error('Error removing photo:', error);
      setError('Failed to remove photo. Please try again.');
    }
  };

  const saveShowcaseInfo = async () => {
    try {
      const user = auth.currentUser;
      if (!user) throw new Error('No authenticated user found');

      await api.updateContractorInfo(user.uid, {
        workersComp: workersCompensation,
        insurance: insurance,
        // Remove displayPhotos from here
      });
    } catch (err) {
      console.error('Error saving showcase info:', err);
      setError('Failed to save your information. Please try again.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await saveShowcaseInfo();
    setLoading(false);
    navigate('/constripe');
  };

  const handleBackToDetails = async () => {
    await saveShowcaseInfo();
    navigate('/conbio');
  };

  const handleSkip = () => {
    navigate('/concomplete');
  };
  
  if (loading) {
    return (
      <div className="conshowcase-container">
        <div className="conshowcase-box">
          <div className="loading-container">
            <div className="loading-spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="conshowcase-container">
      <div className="conshowcase-box">
        <img src="https://homerunns.com/wp-content/uploads/2024/09/Logo_White.png" alt="HomeRunns Logo" className="conshowcase-logo" />
        <h1>Showcase your expertise</h1>
        <div className="header-underline"></div>
        <h2>Upload photos of your past projects (max 5)</h2>
        <div className="photo-upload-area">
          {photos.length < 5 && (
            <label htmlFor="photo-upload" className="photo-upload-label">
              <FaPlus className="upload-icon" />
            </label>
          )}
          <input
            id="photo-upload"
            type="file"
            accept="image/*"
            multiple
            onChange={handlePhotoUpload}
            className="photo-upload-input"
          />
          {photos.map((photoURL, index) => (
            <div key={index} className="photo-container">
              <img src={photoURL} alt={`Uploaded ${index + 1}`} className="uploaded-photo" />
              <button 
                className="remove-photo-button" 
                onClick={() => handlePhotoRemove(photoURL)}
              >
                <FaTimes />
              </button>
            </div>
          ))}
        </div>
        <h1>Reassure Homeowners with Your Coverage</h1>
        <div className="header-underline"></div>
        <form onSubmit={handleSubmit}>
          <table className="coverage-table">
            <tbody>
              <tr>
                <td className="question">Do you have workers compensation?</td>
                <td>
                  <select
                    value={workersCompensation}
                    onChange={(e) => setWorkersCompensation(e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Exemption">I Have an exemption</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td className="question">Do you have insurance?</td>
                <td>
                  <select
                    value={insurance}
                    onChange={(e) => setInsurance(e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="button-container">
            <button type="button" onClick={handleBackToDetails} className="back-to-details-button">Back to Details</button>
            <div className="spacer"></div>
            <button type="submit" className="submit-button">Continue</button>
          </div>
        </form>
        {error && <p className="error-message">{error}</p>}
        <a href="#" onClick={handleSkip} className="skip-link">Skip for now</a>
      </div>
    </div>
  );
}

export default ConShowcase;
