import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import './Login.css'; // Make sure this line is present
import { getVendorInfo } from '../api'; // Import the getVendorInfo function

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    const auth = getAuth();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const uid = userCredential.user.uid;
      
      // Check if the user is a vendor
      try {
        await getVendorInfo(uid);
        // If getVendorInfo succeeds, it's a vendor, so allow login
        navigate('/converify');
      } catch (vendorError) {
        if (vendorError.message === 'Failed to fetch vendor info') {
          // If it's not a vendor (404 error), show the error message
          setError('This tool is currently only for Contractor accounts.');
        } else {
          // If there's another error, show it
          setError('An error occurred. Please try again.');
        }
      }
    } catch (error) {
      console.error('Error during login:', error);
      setError('Invalid email or password. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <img src="https://homerunns.com/wp-content/uploads/2024/09/Logo_White.png" alt="HomeRunns Logo" className="login-logo" />
        <h1>HomeRunns Sign Up</h1>
        <p className="stylized-text">Please sign in below to access HomeRunns Sign Up</p>
        {error && <p className="error">{error}</p>}
        <form onSubmit={handleLogin}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter your password"
            required
          />
          <button type="submit" disabled={loading} className="sign-in-button">
            {loading ? 'Signing in...' : (
              <>
                Sign into 
                <img src="https://homerunns.com/wp-content/uploads/2024/09/Logo_White.png" alt="HomeRunns" className="button-logo" />
              </>
            )}
          </button>
        </form>
        <p className="signup-link">
          Don't have an account? <Link to="/signup">Sign Up</Link>
        </p>
      </div>
    </div>
  );
}

export default Login;
