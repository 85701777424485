import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { api } from '../api';
import './ConVerify.css';

function ConVerify() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        if (currentUser.emailVerified) {
          navigate('/conservices');
        } else {
          setLoading(false);
        }
      } else {
        setUser(null);
        setLoading(false);
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    if (user && !user.emailVerified) {
      const intervalId = setInterval(() => {
        window.location.reload();
      }, 5000);

      return () => clearInterval(intervalId);
    }
  }, [user]);

  const handleBack = () => {
    navigate('/conservices');
  };

  const handleResendVerification = async () => {
    if (user) {
      try {
        await api.post('/sendverificationemail/send', {
          email: user.email,
          displayName: user.displayName || user.email.split('@')[0]
        });
        alert('Verification email sent. Please check your inbox.');
      } catch (error) {
        console.error('Error sending verification email:', error);
        alert('Failed to send verification email. Please try again.');
      }
    }
  };

  if (loading) {
    return <div className="converify-container">Loading...</div>;
  }

  if (!user) {
    return <div className="converify-container">No user found. Please log in.</div>;
  }

  return (
    <div className="converify-container">
      <div className="converify-box">
        <img src="https://homerunns.com/wp-content/uploads/2024/09/Logo_White.png" alt="HomeRunns Logo" className="converify-logo" />
        <h1>Verify Your Email</h1>
        <p>We've sent a verification email to {user.email}.</p>
        <p>Please check your email and click the verification link to continue.</p>
        <p>This page will automatically refresh to check your verification status.</p>
        <button onClick={handleResendVerification} className="verify-button">Resend Verification Email</button>
        <button onClick={handleBack} className="back-button">Back</button>
      </div>
    </div>
  );
}

export default ConVerify;
