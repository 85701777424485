import React, { createContext, useState, useContext, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, onSnapshot } from 'firebase/firestore';
import { nv } from '../utils/environmentUtils';

const VendorContext = createContext();

export const useVendor = () => useContext(VendorContext);

export const VendorProvider = ({ children }) => {
  const [vendor, setVendor] = useState(null);
  const [stripeAccount, setStripeAccount] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const db = getFirestore();

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const vendorCollectionName = nv('vendors');
        const stripeCollectionName = nv('stripeaccts');
        const vendorDocRef = doc(db, vendorCollectionName, user.uid);
        const stripeDocRef = doc(db, stripeCollectionName, user.uid);
        
        const vendorListener = onSnapshot(
          vendorDocRef,
          (docSnapshot) => {
            if (docSnapshot.exists()) {
              setVendor({ id: docSnapshot.id, ...docSnapshot.data() });
            } else {
              setVendor(null);
            }
          },
          (err) => {
            setError(err.message);
            setLoading(false);
          }
        );

        const stripeListener = onSnapshot(
          stripeDocRef,
          (docSnapshot) => {
            if (docSnapshot.exists()) {
              setStripeAccount({ id: docSnapshot.id, ...docSnapshot.data() });
            } else {
              setStripeAccount(null);
            }
            setLoading(false);
          },
          (err) => {
            setError(err.message);
            setLoading(false);
          }
        );

        return () => {
          vendorListener();
          stripeListener();
        };
      } else {
        setVendor(null);
        setStripeAccount(null);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <VendorContext.Provider value={{ vendor, stripeAccount, loading, error }}>
      {children}
    </VendorContext.Provider>
  );
};
